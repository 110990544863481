const baseFitmentFields = ['Year', 'Make', 'Model', 'Submodel'];
const extraFitmentFields = [];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern', 'wheel_offset'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields, 'Universal'];

const categorySelectionPageUrl = '/categories/';
const brandSelectionPageUrl = '/brands/';
const isCategoryPage = window.document.body.classList.contains('category');
const isVehicleLandingPage = !!window.document.querySelector('#cm_landing-page');
const isVehicleGalleryPage = !!window.document.querySelector('#cm_fitment-gallery');
const isVehicleGalleryDetailsPage =
  !!window.location.pathname.startsWith('/vehicle-gallery/') &&
  window.location.pathname !== '/vehicle-gallery/';

// get the number of tires we have to add to the cart after selecting the wheels
window.Convermax.getTiresQtyForPackage = () =>
  window.document
    .querySelector('#cm_MountedPackageDialogButton .cm_button')
    .getAttribute('mounted-package-quantity');

window.Convermax.redirectToTiresCategory = (productId) => async (e) => {
  const { href, pathname } = window.location;

  const redirectPath = href.includes('WheelProductID')
    ? '/cart.php'
    : pathname === '/mounted-packages/'
      ? `/tires/?WheelProductID/${productId}/${window.Convermax.getVehicleSearchParams()}`
      : null;

  if (redirectPath) {
    e.preventDefault();
    e.stopPropagation();

    await window.Convermax.addToCartProduct(productId);

    window.location.replace(redirectPath);
  }
};

window.Convermax.addToCartProduct = async (productId, quantity = 4) => {
  const currentCart = await getCart();

  // on bigcommerce we can't just add a product to the cart,
  // because if the cart is not empty we must first get the cart object and its id,
  // if the cart was empty we must first create a new cart,
  // then get its id and add the product to the new cart.
  if (currentCart.length === 0) {
    await createCart(productId, quantity);
  } else {
    await addCartItem(currentCart[0].id, productId, quantity);
  }

  const cartQuantity = window.document.querySelector('.navUser-item--cart .cart-quantity');
  if (cartQuantity) {
    cartQuantity.innerText = parseInt(cartQuantity.innerText) + quantity;
  }
};

window.Convermax.openMountedPackageDialog = async (productId, quantity, category) => {
  await window.Convermax.addToCartProduct(productId, quantity);

  if (category.includes('Wheel')) {
    const dialogContainer = window.document.querySelector('#cm_MountedPackageDialog');
    const dialogButton = window.document.querySelector('#cm_MountedPackageDialogButton .cm_button');

    if (!dialogButton || !dialogContainer) {
      return;
    }

    dialogContainer.addEventListener('click', () => {
      dialogContainer.style.display = 'none';
    });

    // add attribute with the quantity of wheels which added in the cart.
    dialogButton.setAttribute('mounted-package-quantity', quantity);

    dialogButton.click();
  }
};

async function createCart(id, quantity) {
  return fetch(`/api/storefront/carts`, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      lineItems: [
        {
          quantity: quantity,
          productId: id,
        },
      ],
    }),
  }).then((response) => response.json());
}

async function getCart() {
  return fetch('/api/storefront/carts', {
    method: 'GET',
    credentials: 'same-origin',
  }).then((response) => response.json());
}

async function addCartItem(cartId, productId, quantity) {
  const route = '/api/storefront/carts/' + cartId + '/items';

  return fetch(route, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      lineItems: [
        {
          quantity: quantity,
          productId: productId,
        },
      ],
    }),
  });
}

function getLocalPreselection(pageType, defaults) {
  if (isVehicleLandingPage) {
    return [{ field: 'Vehicle', term: window.location.pathname }];
  }

  const isCategoryPage = pageType === 'category';
  const isBrandPage = pageType === 'brand';

  if ((isCategoryPage || isBrandPage) && defaults.termFromBreadcrumbs !== 'Home') {
    const breadcrumbsSelector = '.breadcrumbs .breadcrumb .breadcrumb-label';

    const parseBreadcrumbs = (...elements) => {
      const isMetaFromZero = elements.some((el) => el.querySelector('meta')?.getAttribute('content') === '0');

      return elements
        .map((elem) => {
          const elemFirstChild = elem.firstElementChild;
          const text = (elemFirstChild.textContent || elemFirstChild.innerText).trim();
          const metaAttr = elemFirstChild.querySelector('meta')?.getAttribute('content');
          const ignore = isMetaFromZero ? metaAttr === '0' : metaAttr === '1';

          return !ignore && text;
        })
        .filter(Boolean);
    };

    const getTermFromBreadcrumbs = () =>
      parseBreadcrumbs(...window.document.querySelectorAll(breadcrumbsSelector)).join('>');

    const trimmedTermFromBreadcrumbs = getTermFromBreadcrumbs()?.replace('Shop>', '');

    return [
      {
        field: isCategoryPage ? 'category' : 'brand_name',
        term: trimmedTermFromBreadcrumbs,
        treeLevel: isCategoryPage ? trimmedTermFromBreadcrumbs.split('>').length - 1 : null,
      },
    ];
  }

  return defaults.getter(pageType);
}

const appendVehicleGalleryLink = (productData, fields, parentSelector) => {
  const parentElem = window.document.querySelector(parentSelector);
  if (!parentElem) {
    return;
  }

  const dataEntries = Object.entries(productData)
    .filter(([field]) => fields.includes(field))
    .map(([field, value]) => ({ field, value }));

  if (!dataEntries.length) {
    return;
  }

  const mapField = (field) => {
    switch (field) {
      case 'vehicle_year':
        return 'Year';
      case 'vehicle_make':
        return 'Make';
      case 'vehicle_model':
        return 'Model';
      case 'wheel_brand':
      case 'tire_brand':
        return 'brand_name';
      default:
        return field;
    }
  };

  const url = window.Convermax.createSearchUrl(
    {
      selection: dataEntries.flatMap(({ field, value }) => {
        const mappedField = mapField(field);
        return Array.isArray(value)
          ? value.map((v) => ({ field: mappedField, term: v }))
          : { field: mappedField, term: value };
      }),
    },
    { goToSearchPage: true },
  );

  if (!url) {
    return;
  }

  const linkElem = window.document.createElement('a');
  linkElem.className = 'cm_vehicle-gallery-link';

  linkElem.onclick = () => {
    window.Convermax.discardVehicle();
    window.open(url, '_blank');
  };

  const linkText = document.createTextNode('Browse products');
  linkElem.appendChild(linkText);

  parentElem.appendChild(linkElem);
};

function AfterInit() {
  if (!isVehicleGalleryDetailsPage) {
    return;
  }

  const intervalId = setInterval(() => {
    if (window.document.querySelector('#vehicle-gallery-page')) {
      clearInterval(intervalId);

      // "/vehicle-gallery/1/1990-Nissan-Skyline-Falken-KOKORO-MonoC-Coilovers" -> "1"
      const [, id] = window.location.pathname.split('/').filter(Boolean);
      if (!id) {
        return;
      }

      window.Convermax.getProductAsync(id).then((productData) => {
        appendVehicleGalleryLink(
          productData,
          ['vehicle_year', 'vehicle_make', 'vehicle_model'],
          '#vehicle-gallery-page .vehicle-tab',
        );

        appendVehicleGalleryLink(
          productData,
          ['wheel_diameter', 'wheel_width', 'wheel_offset', 'wheel_brand', 'wheel_model'],
          '#vehicle-gallery-page .wheel-tab',
        );

        appendVehicleGalleryLink(
          productData,
          ['tire_width', 'tire_aspect', 'tire_rim', 'tire_brand', 'tire_model'],
          '#vehicle-gallery-page .tire-tab',
        );
      });
    }
  }, 500);

  setTimeout(() => clearInterval(intervalId), 10000);
}

const fieldsForRelated = ['category', 'WheelProductID'];
function getSelection(itemData) {
  return fieldsForRelated.flatMap((field) => {
    const value = itemData[field];
    return !value
      ? []
      : Array.isArray(value)
        ? value.map((term) => ({ field, term }))
        : { field, term: value };
  });
}

function responseHandler(response) {
  const { Facets, TotalHits } = response;

  const isProductPage = !!window.document.querySelector('.container .productView');
  const isRelatedResponse = !!Facets.find((f) => f.FieldName === 'WheelProductID');

  if (isProductPage && isRelatedResponse) {
    if (window.Convermax.isVehicleSelected() && TotalHits > 0) {
      window.document.querySelector('#cm_MountedPackageDialog').style.display = 'flex';
    } else {
      window.document.body.classList.remove('cm_no-scroll');

      return window.location.assign('/cart.php');
    }
  }

  return response;
}

export default {
  storeId: isVehicleGalleryPage || isVehicleGalleryDetailsPage ? 'threepieceus-gallery' : 'threepieceus',
  platform: 'bigcommerce',
  AfterInit,
  getLocalPreselection,
  responseHandler,
  SearchRequestDefaults: {
    pageSize: 30,
  },
  page: {
    getPageType: (defaults) =>
      window.location.pathname === categorySelectionPageUrl ||
      window.location.pathname === brandSelectionPageUrl
        ? 'parts'
        : defaults.getter(),
  },
  pagination: {
    edgeRange: 0,
    centralRange: 5,
    disableDelimeters: true,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
    ],
    expectResponseRedirect: true,
    forceVehicleSelection: true,
    isAutoVehicleSelectionDisabled: isVehicleGalleryPage || isCategoryPage || isVehicleLandingPage,
    isVehicleSelectionIsolated: true,
    categorySelectionPageRedirect: true,
  },
  facets: {
    rangedFacet: [
      { fields: ['price'], name: 'priceFacet' },
      { fields: ['tire_load_index'], name: 'sliderFacet' },
      { fields: ['wheel_bore'], name: 'sliderFacet', step: '1/10//1', slider: { mode: 'LowerBound' } },
    ],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '.page--category .main .container',
      template: 'SearchPage',
      visibleIf: () =>
        window.location.pathname !== categorySelectionPageUrl &&
        window.location.pathname !== brandSelectionPageUrl,
    },
    {
      name: 'VehicleLandingPage',
      type: 'SearchPage',
      location: '#cm_landing-page',
      template: 'SearchPage',
    },
    {
      name: 'VehicleGalleryPage',
      type: 'SearchPage',
      location: '#cm_fitment-gallery',
      template: 'vehicleGallery/SearchPage',
    },
    {
      name: 'SearchHeader',
      sortSelectClass: 'form-select',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Low - High',
        'price:desc': 'Price: High - Low',
      },
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    { name: 'SearchResult' },
    {
      name: 'SearchBox',
      location: {
        selector: '#quickSearch',
        class: 'cm_mobile-hide',
      },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '.navUser-item--mobile-search',
        wrapper: 'li',
        class: 'navUser-item navUser-item--mobile-search',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'HomeVehicleWidget_tab',
      type: 'VehicleWidget',
      location: '.cm_tab-content.cm_vehicle-search',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 650,
    },
    {
      name: 'HomeWheelPanel_tab',
      type: 'RequestPanel',
      location: '.cm_tab-content.cm_wheel-search',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      selectFields: [...wheelsFields, 'brand_name'],
    },
    {
      name: 'HomeTirePanel_tab',
      type: 'RequestPanel',
      location: '.cm_tab-content.cm_tire-search',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      selectFields: [...tiresFields, 'brand_name'],
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      isAlwaysColumnLayout: true,
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
      isAlwaysColumnLayout: true,
      doNotRedirectOnVehicleSelect: isVehicleGalleryPage || isCategoryPage || isVehicleLandingPage,
    },
    {
      name: 'Garage',
      location: {
        insertBefore: '.navUser-item--account',
        wrapper: 'li',
        class: 'navUser-item navUser-item--cart',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: { insertAfter: 'body .productView .productView-specs' },
      template: 'fitmentSearch/verifyFitment',
      visibleIf: () => !window.Convermax?.isPageWithoutVerify,
    },
    {
      name: 'RelatedItems',
      template: 'product/relatedItems',
      count: 6,
      getSelection,
    },
    {
      name: 'WheelTireVehicleInfo',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '.page--category .main .container',
      template: 'facetTiles/hierarchicalContainer',
      visibleIf: () => window.location.pathname === categorySelectionPageUrl,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '.page-content-subcategories',
      template: 'facetTiles/alphabeticalContainer',
      visibleIf: () => window.location.pathname === brandSelectionPageUrl,
      view: 'grid',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields: ['Universal'],
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'MountedPackageDialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/mountedPackageDialog',
    },
    {
      name: 'MountedPackageDialogButton',
      type: 'DialogButton',
      location: {
        lastChildOf: 'body',
        class: 'cm_hide',
      },
      template: 'fitmentSearch/customDialogButton',
      dialogName: 'MountedPackageDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
